import React from "react";
import { Input, Spin, Icon, message, Popover } from "antd";
import { observer } from "mobx-react";

import "./index.less";

const IconFont = Loader.loadBaseComponent("IconFont");
const HightLevel = Loader.loadBaseComponent("HightLevel");
const antIcon = <Icon type="loading" spin />;

@Decorator.businessProvider("user")
@observer
class SearchImageInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropLoading: false,
      popVisible: false,
      popoverType: 1,
      keywords: [],
      pictureUrl: "",
      searchList: [],
      recommends: [],
      isFocus: false
    };
    this.uploadRef = React.createRef();
    this.headerRef = React.createRef();
    this.iconRef = React.createRef();
    this.inputRef = React.createRef();
    this.timer = null;
  }

  componentDidMount() {
    document.body.addEventListener("click", this.hideProperLayout, false);
    document.addEventListener("keydown", this.deleteRcommends);
  }

  deleteRcommends = e => {
    try {
      const SELECT = window.getSelection().focusNode.className === "search-label";
      if (SELECT && e.keyCode === 8) {
        let { pictureUrl, recommends } = this.state;
        if (!!recommends.length) {
          recommends.pop();
        } else {
          if (pictureUrl) {
            pictureUrl = "";
          }
        }
        this.setState({
          pictureUrl,
          recommends
        });
      }
    } catch (e) {
      console.error("报错", e);
    }
  };
  hideProperLayout = event => {
    const { popVisible } = this.state;
    let flag = event.path.findIndex(v => v === this.headerRef.current) > -1;
    let icon = event.path.findIndex(v => v === this.iconRef.current) > -1;

    if (flag && !popVisible) {
      this.setState({
        popVisible: true,
        popoverType: icon ? 0 : 1
      });
    }
    if (flag) {
      this.inputRef.current.focus();
    }
    if (!flag) {
      this.setState({ popVisible: false });
    }
  };

  componentWillReceiveProps(nextProps) {
    const searchData = nextProps.searchData;
    if (nextProps.searchData !== this.props.searchData) {
      this.setState({
        keywords: searchData.keywords,
        pictureUrl: searchData.pictureUrl,
        recommends: searchData.recommends
      });
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.hideProperLayout, false);
    document.body.removeEventListener("keydown", this.deleteRcommends);
    clearTimeout(this.timer);
    this.timer = null;
    this.uploadRef = null;
    this.headerRef = null;
    this.iconRef = null;
    this.inputRef = null;
  }

  onDrop = e => {
    e.preventDefault();
    Utils.stopPropagation(e);
    let df = e.dataTransfer.items[0];
    let file = df.getAsFile();
    this.upDataImg(file);
  };
  onDragOver = e => {
    e.preventDefault();
  };

  upDataImg = async file => {
    const limitSize = file.size / 1024 / 1024,
      MAX_SIZE = 5;
    if (limitSize > MAX_SIZE) {
      message.error(`图片大小不能超过${MAX_SIZE}M!`);
      return;
    }
    if (!file.size) {
      return message.warn('图片文件错误!');
    }
    this.setState({ dropLoading: true });
    let newFile = file;
    if (file.type !== "image/svg+xml") {
      newFile = await Utils.imageCompress(file);
    }
    let formData = new FormData();
    formData.append("file", newFile);
    Service.person
      .uploadPersonPicture(formData)
      .then(res => {
        this.setState({
          dropLoading: false,
          pictureUrl: res.data.url
        });
      })
      .catch(e => {
        message.warn("图片结构化失败，请刷新或等待一段时间后再上传");
        this.setState({
          dropLoading: false
        });
      });
  };

  deleteImgUrl = () => {
    this.setState({
      pictureUrl: ""
    });
  };

  fileInputChange = e => {
    let file = e.target.files[0];
    this.upDataImg(file);
  };

  search = () => {
    let { pictureUrl, keywords, recommends } = this.state;
    this.setState({ popVisible: false });
    this.props.search && this.props.search({ pictureUrl, keywords, recommends });
  };

  /**
   * @des 搜索内容改变
   */
  handleInputChange = e => {
    let keywords = e.target.value;
    this.setState({
      keywords: e.target.value ? [keywords] : []
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      Service.person
        .searchPersonByKeywords({
          keywords,
          limit: 3,
          offset: 0
        })
        .then(res => {
          this.setState({
            searchList: res.data.list
          });
        });
    }, 500);
  };

  labelClick = parms => {
    const { recommends } = this.state;
    let chose = recommends.findIndex(v => v.id === parms.value);
    if (chose === -1) {
      recommends.push({ id: parms.value, type: 0, description: parms.label });
    } else {
      recommends.splice(chose, 1);
    }
    this.setState({
      recommends
    });
  };

  deleteLabel = parms => {
    let { recommends } = this.state;
    let index = recommends.findIndex(v => v.id === parms.id);
    recommends.splice(index, 1);
    this.setState({
      recommends
    });
  };

  lenveClick = data => {
    let { recommends } = this.state;
    const index = recommends.findIndex(v => v.id === data.id);
    if (index === -1) {
      let parms = {
        id: data.id,
        type: data.type,
        description: data.description
      };
      recommends.push(parms);
      this.setState({
        recommends,
        searchList: [],
        keywords: []
      });
    }
  };

  render() {
    let { dropLoading, pictureUrl, popVisible, popoverType, recommends, keywords, searchList, isFocus } = this.state;
    const { className = "" } = this.props;
    let list = [
      ...Dict.map.personnelAttr,
      ...Dict.map.gait,
      ...Dict.map.height,
      ...Dict.map.fatAndThin,
      ...Dict.map.identity,
      ...Dict.map.behaviorAttr,
      ...Dict.map.aidBehavior,
      ...Dict.map.aidBehaviorCode,
      ...Dict.map.appearPlace
    ];
    const NO_CODE = ["118703"];
    list = list.filter(v => !NO_CODE.includes(v.value));
    const content = pictureUrl => (
      <div className="picture-box">
        <img className="picture-img" src={pictureUrl} alt="" />
      </div>
    );
    return (
      <div className={`search-image-input ${className}`}>
        <div className="search-header" ref={this.headerRef}>
          <div className="search-input-label">
            <div className={isFocus ? "search-label focus" : "search-label"}>
              {pictureUrl && (
                <Popover getPopupContainer={() => this.headerRef.current} placement="bottom" content={content(pictureUrl)}>
                  <div className="img-box">
                    <img src={pictureUrl} className="header-img" alt="" />
                    <IconFont
                      type={"icon-S_Photo_MarkNo"}
                      className="header-img-close"
                      theme="outlined"
                      onClick={this.deleteImgUrl}
                    />
                    <div className="modal-img" />
                  </div>
                </Popover>
              )}
              {recommends.map(v => {
                return (
                  <div className={`label-box ${v.type === "1" ? "label-box-person" : ""}`}>
                    {v.description}
                    <IconFont
                      type={"icon-S_Photo_MarkNo"}
                      className="header-label-close"
                      theme="outlined"
                      onClick={() => this.deleteLabel(v)}
                    />
                  </div>
                );
              })}
              <Input
                className="upload-header"
                value={keywords}
                ref={this.inputRef}
                onFocus={() => this.setState({ isFocus: true })}
                onBlur={() => this.setState({ isFocus: false })}
                placeholder={recommends.length > 0 ? "" : "请输入人员相关属性进行查询，如姓名、地址、身份证号、各类标签"}
                onChange={this.handleInputChange}
                onPressEnter={this.search}
              />
            </div>
            <div className="search-button" onClick={this.search}>
              <IconFont type={popoverType === 0 ? "icon-M_Bar_PhotoSearch" : "icon-S_Edit_Search"} theme="outlined" />搜 索
            </div>
            {!popVisible && (
              <span ref={this.iconRef}>
                <IconFont type={"icon-M_Bar_PhotoSearch"} theme="outlined" className="header-icon" />
              </span>
            )}
          </div>
          {popVisible && (
            <div className="search-image-input-pop">
              {popoverType === 0 ? (
                <div className="content">
                  {dropLoading ? (
                    <div className="loading-content">
                      <Spin indicator={antIcon} />
                      <div className="spin-text">正在加载图片</div>
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className="content-image-box" onDrop={this.onDrop} onDragOver={this.onDragOver}>
                        <IconFont type={"icon-XL_Bar_AddImg"} theme="outlined" />
                        <div className="box-text">拖拽图片到这里搜图</div>
                      </div>
                      <label className="image-upload">
                        <IconFont type={"icon-S_Arrow_MoveUp"} theme="outlined" />
                        <Input
                          type="file"
                          ref={this.uploadRef}
                          accept="image/*"
                          onChange={this.fileInputChange}
                          style={{ visibility: "hidden", position: "fixed" }}
                        />
                        本地上传图片
                      </label>
                    </React.Fragment>
                  )}
                </div>
              ) : (
                <div className="search-content" ref={this.tipsContentRef}>
                  <div className="search-list">
                    {searchList.map(v => {
                      return (
                        <div className="list" onClick={() => this.lenveClick(v)}>
                          <IconFont type={"icon-M_Bar_Magnifier"} theme="outlined" />
                          <HightLevel keyword={keywords} name={v.description} />
                        </div>
                      );
                    })}
                  </div>
                  <div className="search-label" ref={this.searchLabelRef}>
                    <div className="label-header">试试标签快速查：</div>
                    <div className="label-content">
                      {list.map(v => {
                        return (
                          <span
                            className={`label ${recommends.find(item => item.id === v.value) ? "chose-label" : ""} `}
                            onClick={() => this.labelClick(v)}
                          >
                            {v.label}
                          </span>
                        );
                      })}
                    </div>
                    <div className="label-header">年龄段：</div>
                    <div className="label-content">
                      {[
                        { value: "100801", label: "小孩" },
                        { value: "100802", label: "青年" },
                        { value: "100803", label: "中年" },
                        { value: "100804", label: "老人" }
                      ].map(v => {
                        return (
                          <span
                            className={`label ${recommends.find(item => item.id === v.value) ? "chose-label" : ""} `}
                            onClick={() => this.labelClick(v)}
                          >
                            {v.label}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SearchImageInput;
